<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-input type="text" v-model="q.answer[0].data" />
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q8',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '당뇨병 진단일'
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '당뇨병 진단일',
        answer: [
          {
            data: '',
          },
        ],
      };
    }
  },
};
</script>